import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Header from "./Header";
import Navigation from "./Navigation";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HomeIcon from "@mui/icons-material/Home";
import { useTheme } from "@mui/material";
import DrawerS from "./Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Stack, Tooltip } from "@mui/material";
import Auth from "./Auth";
import { useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@mui/icons-material";
import { logout } from "../../store/actions/authActions";
import Unauthorized from "./Unauthorized";

const drawerWidth = 220;
const navItems = ["Home", "About", "Contact"];

function DrawerAppBar(props) {
  const { window, Child } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = <DrawerS />;
  const drawerWidth = 200;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {user?.user && user.user.isPlatformAdmin && (
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              backgroundColor: theme.palette.primary.main,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              backgroundColor: theme.palette.primary.main,
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {user?.user ? (
          user?.user?.isPlatformAdmin ? (
            <>
              <Toolbar />
              <AppBar
                component="nav"
                sx={{
                  flexGrow: 1,

                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
              >
                <Toolbar>
                  <Stack
                    width="100vw"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack alignItems="center" direction="row">
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Stack direction="row" spacing={1}>
                        <Stack
                          onClick={() => navigate("/")}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {/* <HomeIcon sx={{ width: "30px", height: "30px" }} /> */}
                        </Stack>
                        <Typography
                          onClick={() => navigate("/")}
                          variant="h6"
                          component="div"
                          sx={{
                            flexGrow: 1,
                            display: { xs: "none", sm: "block" },
                            cursor: "pointer",
                          }}
                        >
                          {/* zapilio Platform Admin */}
                        </Typography>
                      </Stack>
                    </Stack>
                    {user?.user && (
                      <Stack
                        sx={{ cursor: "pointer" }}
                        direction="row"
                        spacing={1}
                      >
                        <Avatar src={user?.user?.avatar}>
                          {user?.user?.displayName?.[0].toUpperCase()}
                        </Avatar>
                        <Stack>
                          <Typography color="fonts.white" variant="text9B">
                            {user?.user?.displayName}
                          </Typography>
                          <Typography variant="text10M" color="fonts.white">
                            {user?.user?.email}
                          </Typography>
                        </Stack>
                        <Tooltip title="Logout">
                          <IconButton onClick={() => dispatch(logout())}>
                            <LogoutOutlined sx={{ color: "#fff" }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  </Stack>
                </Toolbar>
              </AppBar>{" "}
              <Child />
            </>
          ) : (
            <Unauthorized />
          )
        ) : (
          <Auth />
        )}
      </Box>
    </Box>
  );
}

function ResponsiveDrawer(props) {
  const theme = useTheme();
  const { window, Child } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = <DrawerS />;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            backgroundColor: theme.palette.primary.main,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            backgroundColor: theme.palette.primary.main,
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Child />
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
