import {
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Skeleton,
  FormControl,
  TextField,
  TablePagination,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomerTable from "./CustomerTable";
import { useDispatch, useSelector } from "react-redux";
import LargeLoading from "../../components/utils/largeLoading";
import { getCustomers } from "../../store/actions/CustomerActions";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const initialfilter = {
  size: 10,
  name: "",
  page: 1,
  createdAt: "",
  segment: "",
  planEnd: "",
  sortBy: "planEnd",
  sortDirection: "1",
  startDate: "",
  endDate: "",
};

const Customers = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(initialfilter);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { customers } = useSelector((state) => state?.customer);
  const [searchField, setSearchField] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [segment, setSegment] = useState("");

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleSeacrh = (e) => {
    e.preventDefault();
    const newFilter = { ...filter };
    newFilter.name = searchField;
    updateData(newFilter);
    setFilter(newFilter);
  };

  const updateData = (filter) => {
    dispatch(getCustomers(setLoading, { ...filter }));
  };

  const handleClear = () => {
    setSegment("");
    setSearchField("");
    setSelectedDate("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    const newFilter = { ...initialfilter };
    updateData(newFilter);
    setFilter(newFilter);
  };

  useEffect(() => {
    const page = searchParams.get("page");
    const size = searchParams.get("size");
    const temp = {
      ...filter,
      page: page ? parseInt(page) : 1,
      size: size ? parseInt(size) : 10,
    };

    dispatch(
      getCustomers(setLoading, { ...filter, page: temp.page, size: temp.size })
    );
    setFilter(temp);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handledropdown = (e) => {
    setSegment(e.target.value);

    const newFilter = { ...filter };
    newFilter.segment = e.target.value;
    updateData(newFilter);
    setFilter(newFilter);
  };

  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  // ...

  const handleStartDateFilter = (e) => {
    setSelectedStartDate(e.format("YYYY/MM/DD"));
  };

  const handleEndDateFilter = (e) => {
    setSelectedEndDate(e.format("YYYY/MM/DD"));
    const newFilter = { ...filter };
    newFilter.startDate = selectedStartDate;
    newFilter.endDate = e.format("YYYY/MM/DD");
    updateData(newFilter);
    setFilter(newFilter);
  };

  const handleDate = (e) => {
    const current = { ...filter };
    current.planEnd = e.target.value;
    setFilter(current);
  };

  const handlePlanEndSort = () => {
    const current = { ...filter };
    const direction = current.sortDirection === "1" ? "-1" : "1";
    current.sortDirection = direction;
    setFilter(current);
    dispatch(getCustomers(setLoading, { ...current }));
  };
  return (
    <>
      <Grid
        sx={{
          width: "100%",
          height: "100vh",
          background: "#F7F9FD",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              lg: "row",
              md: "column",
            },
            justifyContent: "space-between",
          }}
          mb={2}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            display="flex"
            justifyContent="space-between"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                lg: "row",
                md: "column",
              },
            }}
          >
            <Paper
              direction="row"
              display="flex"
              sx={{
                width: "300px",
                padding: "8px 35px",
                boxShadow: "0px 8px 16px #00000014",
                borderRadius: "8px",
                marginRight: "20px",
                justifyContent: "space-between",
              }}
            >
              <form action="#" onSubmit={handleSeacrh}>
                <input
                  type="text"
                  placeholder=" Search Customer Name"
                  onChange={handleChange}
                  value={searchField}
                  style={{
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                    color: "#3A3A3A",
                    width: "250px",
                  }}
                />
              </form>
            </Paper>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              sx={{ flexDirection: { sm: "column", lg: "row", md: "row" } }}
            >
              <FormControl>
                <InputLabel id="demo-simple-select-label" size="small">
                  Select Segment
                </InputLabel>
                <Select
                  sx={{ width: "190px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={segment}
                  label="Select Segment"
                  onChange={handledropdown}
                >
                  {[
                    "Target",
                    "Non Target",
                    "Design Partner",
                    "Internal",
                    "Ignore",
                  ].map((h, i) => (
                    <MenuItem value={h} key={i}>
                      {h}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    sx={{ width: "150px" }}
                    size="small"
                    label="Start Date"
                    inputFormat="YYYY/MM/DD "
                    onBlur={handleClear}
                    value={selectedStartDate || null}
                    onChange={handleStartDateFilter}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        sx={{ width: "163px" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    size="small"
                    label="End Date"
                    inputFormat="YYYY/MM/DD "
                    onBlur={handleClear}
                    value={selectedEndDate || null}
                    onChange={handleEndDateFilter}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        sx={{ width: "163px" }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <Button
                onClick={handleSeacrh}
                sx={{ fontSize: "16px" }}
                variant="contained"
              >
                Search
              </Button>
              <Tooltip title={t("Reset")} arrow>
                <IconButton onClick={handleClear} sx={{ fontSize: "16px" }}>
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Stack
          direction="row"
          p={1}
          spacing={2}
          justifyContent="flex-end"
        ></Stack>

        <Paper
          sx={{
            width: "100%",
            height: "70px",
            borderRadius: "5px",
            padding: "15px 30px",
            display: "grid",
            gridTemplateColumns: "1.5fr repeat(4, 1fr)",
            marginBottom: "10px",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #0000001A",
          }}
        >
          <Typography variant="text6B">{t("cn")}</Typography>
          <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
            {t("ct")}
          </Typography>
          <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
            {t("cp")}
          </Typography>
          <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
            {t("segment")}
          </Typography>

          <>
            <Typography
              variant="text6B"
              sx={{ color: "#3a3a3a" }}
              onChange={handleDate}
              onClick={handlePlanEndSort}
            >
              {filter.sortDirection === "1" ? (
                <ArrowDropUp sx={{ color: "#3a3a3a", fontSize: "18px" }} />
              ) : (
                <ArrowDropDown sx={{ color: "#3a3a3a", fontSize: "18px" }} />
              )}
              {t("pe")}
            </Typography>
          </>
        </Paper>
        <Paper
          sx={{
            width: "100%",
            borderRadius: "5px",
            gap: "10px",
            marginBottom: "10px",

            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #0000001A",
          }}
        >
          {loading ? (
            <>
              {" "}
              <LargeLoading />{" "}
              <Stack spacing={1}>
                {[...Array(4).keys()].map((k, i) => (
                  <Skeleton
                    key={i}
                    variant="rectangular"
                    height="60px"
                    sx={{ borderRadius: "10px" }}
                  />
                ))}
              </Stack>
            </>
          ) : (
            <Grid>
              {customers?.docs?.length > 0 ? (
                <CustomerTable data={customers} searchData={searchField} />
              ) : (
                <Grid container pl={50}>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "2rem",
                      }}
                    >
                      Not Found:/
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
          {console.log(
            customers?.totalDocs,
            parseInt(filter.size),
            parseInt(filter.page)
          )}
          {/* <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={customers?.totalDocs || 0}
            rowsPerPage={parseInt(filter.size)}
            page={0}
          /> */}
          <TablePagination
            rowsPerPageOptions={[2, 5, 10]}
            component="div"
            count={customers?.totalDocs || 0}
            rowsPerPage={parseInt(filter.size)}
            page={parseInt(filter.page) - 1}
            onPageChange={(e, v) => setSearchParams({ ...filter, page: v + 1 })}
            onRowsPerPageChange={(e) =>
              setSearchParams({ ...filter, size: parseInt(e.target.value) })
            }
          />
        </Paper>
      </Grid>
    </>
  );
};

export default Customers;
