//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const SIGNIN = "SIGNIN";
export const SIGNOUT = "SIGNOUT";

//CUSTOMERS
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERDETAILS = "GET_CUSTOMERDETAILS";
export const ADDNOTES = "ADDNOTES";
export const UPDATESEGMENT = "UPDATESEGMENT";
export const UPDATEDATE = "UPDATDATE";
export const DOWNLOAD = "DOWNLOAD";
export const APPLICANT_CSV = "APPLICANT_CSV";
export const APPLICANTRESPONSE_CSV = "APPLICANTRESPONSE_CSV";
export const GET_SKILLZAPUSERS = "GET_SKILLZAPUSERS";
export const GET_USERS = "GET_USERS";
export const DOWNLOAD_USERS = "DOWNLOAD_USERS";
export const  GET_ASSESSMENTS ="GET_ASSESSMENTS";
export const GET_TOPASSESSMENTS="GET_TOPASSESSMENTS";
export const GET_DASHBOARD="GET_DASHBOARD";
export const DOWNLOAD_TOPASSESSMENTS="DOWNLOAD_TOPASSESSMENTS";
export const DOWNLOAD_REPORT="DOWNLOAD_REPORT";
export const COMPLETED_BEHAVIORAL="COMPLETED_BEHAVIORAL";
export const SIGNEDUP_TECHNICAL="SIGNEDUP_TECHNICAL";
export const SIGNEDUP_BEHAVIORAL="SIGNEDUP_BEHAVIORAL";
export const DROPPED_TECHNICAL="DROPPED_TECHNICAL";
export const DROPPED_BEHAVIORAL="DROPPED_BEHAVIORAL";