import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import moment from "moment";
import Moment from "react-moment";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

export default function CustomerTable({ data = { docs: [] } }) {
  const navigate = useNavigate();
  const inThisWeek = (date) => {
    const today = moment();
    const from_date = today.startOf("week").toString();
    const to_date = today.endOf("week").toString();
    if (
      moment(date).isBetween(from_date, to_date) ||
      moment(date).isBefore(today.toString())
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {data.docs.map((CustomerData, i) => (
        <TableContainer key={i}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  display: "grid",
                  gridTemplateColumns: "0.1fr repeat(5, 1fr)",
                  padding: "15px 30px",
                }}
              >
                <TableCell>
                  <Stack
                    alignItems="center"
                    sx={{ width: "60px", height: "20px" }}
                  >
                    <img
                      src={CustomerData?.logoURL}
                      alt={CustomerData?.name}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </Stack>
                </TableCell>

                <TableCell
                  align="left"
                  onClick={() =>
                    navigate("/CustomerDetails", {
                      state: CustomerData._id,
                    })
                  }
                  sx={{
                    fontSize: "18px",
                    color: "#09337B",
                    cursor: "pointer",
                    minWidth: "120px",
                  }}
                >
                  {CustomerData.name}
                </TableCell>

                <TableCell sx={{ fontSize: "18px" }}>
                  <Moment format="YYYY-MM-DD">{CustomerData.createdAt}</Moment>
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  {CustomerData.plan}
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  {CustomerData.segment}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "18px",
                    color: inThisWeek(CustomerData?.planEnd)
                      ? "#FF0000"
                      : "#000",
                  }}
                >
                  <Moment format="YYYY-MM-DD">{CustomerData.planEnd}</Moment>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
        </TableContainer>
      ))}
    </>
  );
}
