import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import dayjs from "dayjs";
import { DialogActions } from "@material-ui/core";
import { downloadTopAssessments } from "../../store/actions/CustomerActions";
import {  Box, Grid, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../store/actions/CustomerActions";
import DownloadIcon from "@mui/icons-material/Download";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import UserCount from "./userCount";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Label } from "recharts";
import { DesktopDatePicker } from "@mui/x-date-pickers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const initialfilter = {
  days: "",
};
const Dashboard = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  //Top Assessments
  const [value, setValue] = React.useState(0);

  const handleChangeevent = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedRange, setSelectedRange] = useState("5");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [customDatepickerOpen, setCustomDatepickerOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
const [isCustomDateFilterApplied, setIsCustomDateFilterApplied] = useState(false);

const handleRangeChange = (event) => {
  event.stopPropagation();
  if (event.target.value === "custom") {
    setIsDateRangeSelected(false);
    setCustomDatepickerOpen(true);
  } else {
    setIsDateRangeSelected(false);
    setIsCustomDateFilterApplied(false);
    setSelectedRange(event.target.value);
    setStartDate(null);
    setEndDate(null);
    setCustomStartDate(null);
    setCustomEndDate(null);
  }
};

const handleCustomDateFilter = () => {
  setCustomDatepickerOpen(false);
  setIsDateRangeSelected(true);
  setStartDate(customStartDate);
  setEndDate(customEndDate);
};
const assessmentData =
useSelector((state) => state.customer?.dashboard?.assessmentData) || [];

const filteredAssessmentData = useMemo(() => {
  let filtered = assessmentData;
  if (isDateRangeSelected && !isCustomDateFilterApplied && startDate && endDate) {
    filtered = filtered.filter((item) => {
      const date = new Date(item.createdDate);
      return date >= new Date(startDate) && date <= new Date(endDate);
    });
  } else if (
    selectedRange === "custom" &&
    isCustomDateFilterApplied &&
    customStartDate &&
    customEndDate
  ) {
    filtered = filtered.filter((item) => {
      const date = new Date(item.createdDate);
      return (
        date >= new Date(customStartDate) && date <= new Date(customEndDate)
      );
    });
  } else if (selectedRange !== "all" && selectedRange !== "custom") {
    filtered = filtered.slice(-parseInt(selectedRange));
  }
  return filtered;
}, [  assessmentData,  isDateRangeSelected,  selectedRange,  isCustomDateFilterApplied,  startDate,  endDate,  customStartDate,  customEndDate,]);

  console.log(filteredAssessmentData);

  const groupedAssessments = filteredAssessmentData
    .flatMap((data) => data.assessments)
    .reduce((groups, item) => {
      const existing = groups.find(
        (group) => group.name === item.assessmentLibName
      );
      if (existing) {
        existing.count += item.count;
      } else {
        groups.push({ name: item.assessmentLibName, count: item.count });
      }
      return groups;
    }, []);

  const topAssessments = [...groupedAssessments].sort(
    (a, b) => b.count - a.count
  );

  const sortedTopAssessments = [...topAssessments].sort(
    (a, b) => b.count - a.count
  );

  console.log(sortedTopAssessments);

  const randomlySelectedAssessments = topAssessments.sort(
    () => 0.5 - Math.random()
  );

  console.log(randomlySelectedAssessments, "randomly selected assessments");

  const downloadAssessments = () => {
    const filter = { days: selectedRange };
    dispatch(
      downloadTopAssessments(
        setLoading,
        filter,
        startDate || customStartDate || new Date(),
        endDate || customEndDate || new Date()
      )
    );
  };

  console.log(topAssessments, "top");
  const downloadassessments = useSelector(
    (state) => state.customer?.downloadassessments
  );
  console.log(downloadassessments, "download");

  const totalCount = useMemo(() => {
    const filteredCounts = filteredAssessmentData
      .flatMap((data) => data.assessments)
      .map(({ count }) => count)
      .filter((count) => count !== 0); // exclude counts of 0
    return filteredCounts.reduce((a, b) => a + b, 0);
  }, [filteredAssessmentData]);
  const nonZeroItems = sortedTopAssessments.filter((item) => item.count !== 0);
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChangeevent}
            aria-label="basic tabs example"
          >
            <Tab label="Hirezap" {...a11yProps(0)} />
            <Tab label="Skillzap" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          Hirezap
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container direction="row">
            {/* TopAssessments */}
            <Grid item xs={12} sm={6}>
              <Typography
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ fontSize: "18px" }}
              >
                Top Assessments
                <DownloadIcon
                  onClick={downloadAssessments}
                  sx={{ cursor: "pointer" }}
                />
              </Typography>
              <Grid container direction="row">
                <Grid item>
                  <FormControl>
                    <InputLabel htmlFor="selectedRange"> Date Range</InputLabel>
                    <Select
  value={selectedRange}
  inputProps={{
    name: "selectedRange",
    id: "selectedRange",
  }}
  onChange={handleRangeChange}
>
  <MenuItem value="2">Last 2 Days</MenuItem>
  <MenuItem value="5">Last 5 Days</MenuItem>
  <MenuItem value="7">Last 1 Week</MenuItem>
  <MenuItem value="14">Last 2 Week</MenuItem>
  <MenuItem
    value="custom"
    onClick={(event) => {
      event.stopPropagation(); // prevent triggering the first Select component
      setCustomDatepickerOpen(true);
      setSelectedRange("custom"); // set the selectedRange to "custom"
    }}
    sx={{ width: "200px" }}
  >
    Custom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </MenuItem>
</Select>

                    <Dialog
                      open={customDatepickerOpen}
                      onClose={() => setCustomDatepickerOpen(false)}
                    >
                      <DialogContent>
                        <Grid container direction="column">
                          <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        size="small"
                        inputFormat="YYYY/MM/DD "
                        label="Start Date"
                        maxDate={new Date()}
                        value={customStartDate}
                        onChange={(date) => setCustomStartDate(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        onClose={() => {}}
                      />
                    </LocalizationProvider>
                          </Grid>
                          <Grid textAlign="center">to</Grid>
                          <Grid item>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        size="small"
                        inputFormat="YYYY/MM/DD "
                        label="End Date"
                        maxDate={new Date()}
                        value={customEndDate}
                        onChange={(date) => setCustomEndDate(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        onClose={() => {}}
                      />
                    </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions style={{ marginRight: "60px" }}>
                        <Button onClick={handleCustomDateFilter}>Ok</Button>
                      </DialogActions>
                    </Dialog>
                  </FormControl>
                </Grid>

                <Grid item style={{ marginLeft: "310px", marginTop: "20px" }}>
                  {" "}
                  Total Count: &nbsp;{totalCount}
                </Grid>

                <ResponsiveContainer
                  width="95%"
                  height={40 * topAssessments.length}
                >
                  <BarChart
                    data={nonZeroItems} // pass in the sorted top assessments
                    layout="vertical"
                    margin={{ top: 25, right: 20, left: 60, bottom: 20 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <YAxis
                      dataKey="name"
                      type="category"
                      interval={0}
                      tick={{ fontSize: 9, width: 200 }} // set width to a value that fits the longest label
                      label={{
                        position: "insideLeft",
                        angle: -90,
                        offset: -8,
                      }}
                    />
                    <XAxis dataKey="count" type="number" hide />
                    <Tooltip
                      content={({ active, payload, label }) => {
                        if (active && payload && payload.length) {
                          console.log(payload[0].payload); // Log the payload object for debugging
                          return (
                            <Box sx={{ background: "white", padding: 2 }}>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: "13px" }}
                              >
                                {payload[0].payload.name}{" "}
                                {payload[0].payload.assessmentLibName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ fontSize: "13px" }}
                              >
                                {payload[0].value}
                              </Typography>
                            </Box>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="count" fill="#8884d8" minPointSize={25}>
                      <LabelList
                        dataKey="count"
                        position="insideRight"
                        fill="#fff"
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </Grid>
            {/* UserCount */}
            <UserCount />
          </Grid>
        </TabPanel>
      </Box>
    </div>
  );
};

export default Dashboard;
