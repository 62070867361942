import React, { useEffect, useState } from "react";
import { Button, Popover, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// import { saveApplicantNotes } from "../../store/actions/jobActions";
import { useDispatch } from "react-redux";
import { addNotes } from "../../store/actions/CustomerActions";

const AddNotes = ({ id, open, anchorEl, setAnchorEl, data }) => {
  const [note, setNote] = useState("");
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setNote("");
  };

  useEffect(() => {
    if (open) {
      setNote(data?.notes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!saving) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving]);
  const handleSave = () => {
    dispatch(addNotes(data?._id, { note: note }, setSaving));
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Stack sx={{ minWidth: "300px" }} p={1} spacing={1}>
        <Typography variant="text7M" color="fonts.caps">
          Add a note for <b> {data.displayName} </b>{" "}
        </Typography>

        <TextField multiline minRows={2} maxRows={3} size="small" value={note} onChange={(e) => setNote(e.target.value)} />

        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button disabled={saving} onClick={handleClose}>
            Close
          </Button>
          <LoadingButton onClick={handleSave} loading={saving} disableElevation>
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default AddNotes;
