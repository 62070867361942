import React, { useState} from "react";
import {Box,Grid} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadReport,downloadBehavioralcompleted,downloadSignedUpTechnical,downloadSignedUpBehavioral,downloadAssessmentDroppedTechnical,downloadAssessmentDroppedBehavioral} from '../../store/actions/CustomerActions';
import { useDispatch, useSelector } from "react-redux";
import Typography from '@mui/material/Typography';



const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

  const SkillzapPlans=()=> {
  const dispatch = useDispatch();
  const[loading,setLoading]=useState('')
  const  downloadreport= useSelector((state) => state.customer?.downloadreport);
  const completedBehavioral=useSelector((state) => state.customer?.completedBehavioral);
const signedupTechnical=useSelector((state) => state.customer?.signedupTechnical);
const signedupBehavioral=useSelector((state)=>state.customer?.signedupBehavioral);
const droppedTechnical =useSelector((state)=>state.customer?.signedupBehavioral);

  const handleDownload = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadReport(setLoading));
  };

  const handleCompletedBehavioral = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadBehavioralcompleted(setLoading));
  };

  const handleSignedUpTechnical = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadSignedUpTechnical(setLoading));
  };
  const handleSignedUpBehavioral = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadSignedUpBehavioral(setLoading));
  };
  const handledroppedTechnical = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadAssessmentDroppedTechnical(setLoading));
  };

  const handledroppedBehavioral = (event) => {
    event.preventDefault(); // Prevent the default action of the click event
    dispatch(downloadAssessmentDroppedBehavioral(setLoading));
  };
  return (
    <Grid container spacing={10}>

    {/* Completed Assessment */}
      <Grid item xs={12} md={6}   display="flex" alignItems="center" justifyContent="center">
    <Card sx={{ minWidth: 100 ,width:"600px",height:"150px"}}>
      <CardContent>
        <Typography variant="h5" display="flex" alignItems="center" justifyContent="center">
        Completed assessment
        </Typography> 
      </CardContent>
      <Grid item  display="flex" alignItems="center" justifyContent="center" gap={10} > <CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M">Technical</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handleDownload} />
</CardActions>
<CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M">Behavioral</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handleCompletedBehavioral} />
</CardActions>
   </Grid>
    </Card>
    </Grid>
       {/*Dropped Assessment */}
    <Grid item xs={12} md={6}  display="flex" alignItems="center" justifyContent="center">
    <Card sx={{ minWidth: 100 ,width:"600px",height:"150px"}}>
      <CardContent>
        <Typography variant="h5"  display="flex" alignItems="center" justifyContent="center" component="div">
        Dropped assessment - 17 seconds
        </Typography>  
        <Typography   display="flex" alignItems="center" justifyContent="center" component="div">
        Work in progress...
        </Typography> 
      </CardContent>
      <Grid item  display="flex" alignItems="center" justifyContent="center"> <CardActions>
      <DownloadIcon/>
      </CardActions></Grid>
    </Card>
    </Grid>

     {/*SignedUp Assessment */}
    <Grid item xs={12} md={6}  display="flex" alignItems="center" justifyContent="center">
    <Card sx={{ minWidth: 100 ,width:"600px",height:"150px"}}>
      <CardContent>   
        <Typography variant="h5"  display="flex" alignItems="center" justifyContent="center" component="div">
        Signed up, but no assessment
        </Typography>  
      </CardContent>
      <Grid item  display="flex" alignItems="center" justifyContent="center" gap={10} > <CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M">Technical</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handleSignedUpTechnical} />
</CardActions>

<CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M">Behavioral</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handleSignedUpBehavioral} />
</CardActions>
   </Grid>
     
    </Card>
    </Grid>

     {/*  Assessment started, but dropped */} 
    <Grid item xs={12} md={6}   display="flex" alignItems="center" justifyContent="center">
    <Card sx={{ minWidth: 100 ,width:"600px",height:"150px"}}>
      <CardContent>   
        <Typography variant="h5"  display="flex" alignItems="center" justifyContent="center" component="div">
        Assessment started, but dropped
        </Typography>    
      </CardContent>
      <Grid item  display="flex" alignItems="center" justifyContent="center" gap={10} > <CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M">Technical</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handledroppedTechnical} />
</CardActions>
<CardActions style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  <Typography variant="text7M" >Behavioral</Typography>
  <DownloadIcon style={{ cursor: "pointer" }} onClick={handledroppedBehavioral} />
</CardActions>
   </Grid>     
    </Card>
    </Grid>
    </Grid>
  );
}
export default SkillzapPlans;
