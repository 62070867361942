import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import authImg from "../../images/unauthorized.png";

const Unauthorized = () => {
  return (
    <Stack alignItems="center" spacing={2} justifyContent="center" height="70vh">
      <Grid height="400px" width="400px">
        <img src={authImg} alt="auth" style={{ maxWidth: "100%", maxHeight: "100%" }} />
      </Grid>
      <Stack alignItems="center">
        <Typography variant="text5B" color="fonts.title2">
          {" "}
          Unauthorized User{" "}
        </Typography>
        <Typography variant="text7M" color="fonts.sub">
          {" "}
          Your are not authorized as platfrom admin to use this application.{" "}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Unauthorized;
