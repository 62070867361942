import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import DrawerAppBar from "../components/Navigation/Nav";
import Navigation from "../components/Navigation/Navigation";

const useAuth = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = (props) => {
  const auth = useAuth();

  return auth ? <DrawerAppBar Child={Outlet} /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
