import React from "react";
import Navigation from "../components/Navigation/Navigation";
import { Navigate, Outlet } from "react-router-dom";
import DrawerAppBar from "../components/Navigation/Nav";

const PublicRoutes = (props) => {
  return <DrawerAppBar Child={Outlet} />;
};

export default PublicRoutes;
