import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Stack,
  Skeleton,
  Button,
  TablePagination,
} from "@mui/material";
import UsersTable from "./UsersTable";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useSearchParams } from "react-router-dom";
import LargeLoading from "../../components/utils/largeLoading";
import { getUsers } from "../../store/actions/CustomerActions";
import { downloadUsers } from "../../store/actions/CustomerActions";
import DownloadIcon from "@mui/icons-material/Download";

const initialfilter = {
  size: 10,
  search: "",
  page: 1,
};

const Skillzap = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(initialfilter);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [downloadskillzap, setDownloadskillzap] = useState("");
  const skillzapusers = useSelector((state) => state.customer?.skillzapusers);
  const downloadusers = useSelector((state) => state.customer?.downloadusers);
  console.log(downloadusers);

  const { t } = useTranslation();

  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(getUsers(setLoading));
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, [dispatch]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(downloadUsers(setLoading));
  //     setLoading(false);
  //   };
  //   fetchData();
  // }, [dispatch]);
  // useEffect(() => {
  //   // call the downloadUsers action with some filters
  //   dispatch(downloadUsers(setLoading, filter));
  // }, [dispatch]);
  const handleDownload = (event) => {
    event.preventDefault(); // Prevent the default action of the click event

    const filter = { page: 1, search: searchField };
    dispatch(downloadUsers(setLoading, filter));
  };

  console.log(downloadusers);
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const newFilter = { ...filter };
    newFilter.search = searchField;
    updateData(newFilter);
    setFilter(newFilter);
  };

  const updateData = (filter) => {
    dispatch(getUsers(setLoading, { ...filter }));
  };

  const handleClear = () => {
    setSearchField("");
    setDownloadskillzap("");

    const newFilter = { ...initialfilter };
    updateData(newFilter);
    setFilter(newFilter);
  };

  useEffect(() => {
    const page = searchParams.get("page");
    const size = searchParams.get("size");
    const temp = {
      ...filter,
      page: page ? parseInt(page) : 1,
      size: size ? parseInt(size) : 10,
    };

    dispatch(
      getUsers(setLoading, { ...filter, page: temp.page, size: temp.size })
    );
    setFilter(temp);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  //   // Base URL for the API
  //   const baseUrl =
  //     "https://api.zapilio.com/v2/stats/skillzap/users/downloadSkillZapUser";

  //   // Initialize an array with the column headers for the CSV file
  //   const allCsvData = [
  //     ["Email", "Display Name", "Created At", "Education/Company"],
  //   ];

  //   // Function to fetch data from a specific page of the API
  //   const fetchPage = (page) => {
  //     const url = `${baseUrl}?page=${page}`;
  //     return fetch(url).then((response) => response.json());
  //   };

  //   // Function to fetch all pages of the API and add the data to the allCsvData array
  //   const fetchAllPages = async () => {
  //     let page = 1;
  //     let totalPages = 1;
  //     while (page <= totalPages) {
  //       const response = await fetchPage(page);
  //       response.docs.forEach((user) => {
  //         const { email, displayName, createdAt, eduCom } = user;
  //         allCsvData.push([email, displayName, createdAt, eduCom || ""]);
  //       });
  //       totalPages = response.totalPages;
  //       page++;
  //     }
  //   };

  //   // Function to fetch filtered data based on searchField and filter parameters
  //   const fetchFilteredData = async () => {
  //     const { page, size, ...filterParams } = filter;
  //     const query = new URLSearchParams(filterParams).toString();
  //     const url = `${baseUrl}?${query}&page=${page}&size=${size}`;
  //     const response = await fetch(url);
  //     const data = await response.json();
  //     const filteredCsvData = [
  //       ["Email", "Display Name", "Created At", "Education/Company"],
  //     ];
  //     data.docs.forEach((user) => {
  //       const { email, displayName, createdAt, eduCom } = user;
  //       filteredCsvData.push([email, displayName, createdAt, eduCom || ""]);
  //     });
  //     return filteredCsvData;
  //   };

  //   // Download all data
  //   await fetchAllPages();
  //   const allCsvContent =
  //     "data:text/csv;charset=utf-8," +
  //     allCsvData.map((row) => row.join(",")).join("\n");
  //   const allEncodedUri = encodeURI(allCsvContent);
  //   const allLink = document.createElement("a");
  //   allLink.setAttribute("href", allEncodedUri);
  //   allLink.setAttribute("download", "all_users.csv");
  //   document.body.appendChild(allLink);
  //   allLink.click();

  //   // Download filtered data
  //   if (searchField || filter.page !== 1) {
  //     const filteredCsvData = await fetchFilteredData();
  //     const filteredCsvContent =
  //       "data:text/csv;charset=utf-8," +
  //       filteredCsvData.map((row) => row.join(",")).join("\n");
  //     const filteredEncodedUri = encodeURI(filteredCsvContent);
  //     const filteredLink = document.createElement("a");
  //     filteredLink.setAttribute("href", filteredEncodedUri);
  //     filteredLink.setAttribute("download", "filtered_users.csv");
  //     document.body.appendChild(filteredLink);
  //     filteredLink.click();
  //   }
  // };
  // const exportToExcel = () => {
  //   const baseUrl =
  //     "https://dev.api.zapilio.com/v2/stats/skillzap/users/searchSkillZapUser";

  //   const csvData = [
  //     ["Email", "Display Name", "Created At", "Education/Company"],
  //   ];

  //   const fetchPage = (page) => {
  //     const url = `${baseUrl}?page=${page}`;
  //     return fetch(url).then((response) => response.json());
  //   };

  //   const fetchAllPages = async () => {
  //     let page = 1;
  //     let totalPages = 1;
  //     while (page <= totalPages) {
  //       const response = await fetchPage(page);
  //       response.docs.forEach((user) => {
  //         const { email, displayName, createdAt, eduCom } = user;
  //         csvData.push([email, displayName, createdAt, eduCom || ""]);
  //       });
  //       totalPages = response.totalPages;
  //       page++;
  //     }
  //   };

  //   fetchAllPages()
  //     .then(() => {
  //       // convert data to CSV format
  //       const csvContent =
  //         "data:text/csv;charset=utf-8," +
  //         csvData.map((row) => row.join(",")).join("\n");

  //       // download CSV file
  //       const encodedUri = encodeURI(csvContent);
  //       const link = document.createElement("a");
  //       link.setAttribute("href", encodedUri);
  //       link.setAttribute("download", "skillzapusers.csv");
  //       document.body.appendChild(link); // Required for FF
  //       link.click();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  return (
    <>
      {" "}
      <>
        <Grid
          sx={{
            width: "100%",
            height: "100vh",
            background: "#F7F9FD",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                lg: "row",
                md: "column",
              },
              justifyContent: "space-between",
            }}
            mb={2}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              display="flex"
              justifyContent="space-between"
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  lg: "row",
                  md: "column",
                },
              }}
            >
              <Paper
                direction="row"
                display="flex"
                sx={{
                  width: "300px",
                  padding: "8px 35px",
                  boxShadow: "0px 8px 16px #00000014",
                  borderRadius: "8px",
                  marginRight: "20px",
                  justifyContent: "space-between",
                }}
              >
                <form action="#" onSubmit={handleSearch}>
                  <input
                    type="text"
                    placeholder="Search Name/Organization"
                    onChange={handleChange}
                    value={searchField}
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: "18px",
                      color: "#3A3A3A",
                      width: "250px",
                    }}
                  />
                </form>
              </Paper>
              <Tooltip title={t("Reset")} arrow>
                <IconButton onClick={handleClear} sx={{ fontSize: "16px" }}>
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" p={1} spacing={2} justifyContent="flex-end">
              {" "}
              <Button
                variant="contained"
                onClick={handleDownload}
                endIcon={<DownloadIcon />}
              >
                Download
              </Button>
            </Stack>
          </Grid>

          <Paper
            sx={{
              width: "100%",
              borderRadius: "5px",
              gap: "10px",
              marginBottom: "10px",

              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 20px #0000001A",
            }}
          >
            {loading ? (
              <>
                {" "}
                <LargeLoading />{" "}
                <Stack spacing={1}>
                  {[...Array(4).keys()].map((k, i) => (
                    <Skeleton
                      key={i}
                      variant="rectangular"
                      height="60px"
                      sx={{ borderRadius: "10px" }}
                    />
                  ))}
                </Stack>
              </>
            ) : (
              <Grid>
                {skillzapusers?.docs?.length > 0 ? (
                  <UsersTable data={skillzapusers} />
                ) : (
                  <Grid container pl={50}>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "2rem",
                        }}
                      >
                        Not Found:/
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}

<TablePagination
              rowsPerPageOptions={[50, 100, 120]}
              component="div"
              count={skillzapusers?.totalDocs || 0}
              rowsPerPage={parseInt(filter.size)}
              page={parseInt(filter.page) - 1}
              onPageChange={(e, v) =>
                setSearchParams({ ...filter, page: v + 1 })
              }
              onRowsPerPageChange={(e) =>
                setSearchParams({ ...filter, size: parseInt(e.target.value) })
              }
            />
          </Paper>
        </Grid>
      </>
    </>
  );
};

export default Skillzap;
