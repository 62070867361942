import * as actionTypes from "../actionTypes";

const initialState = {
  user: null,
};
export default function AuthReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SIGNIN:
      return { ...state, user: action?.payload };
    case actionTypes.SIGNOUT:
      return { ...state, user: null };
    default:
      return state;
  }
}
