import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Stack } from "@mui/system";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
  Groups2,
  Groups2Outlined,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";

const SideBarLink = ({ title, link, Icon }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentRoute = useLocation().pathname.split("/")[1];

  return (
    <Grid
      container
      onClick={() => {
        title === "logout"
          ? dispatch(logout(navigate))
          : link !== "#"
          ? navigate(`/${link}`)
          : navigate(`#`);
      }}
      sx={{ cursor: "pointer" }}
      direction="row"
      spacing={2}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid
          sx={{
            padding: "5px",
            borderRadius: "5px",
            backgroundColor: "#204688",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Icon}
        </Grid>
        <Typography
          variant="text6N"
          color={currentRoute === link ? "fonts.white" : "fonts.inactive"}
        >
          {t(title)}
        </Typography>
      </Stack>
    </Grid>
  );
};

const DrawerS = ({ title, link, Icon }) => {
  const theme = useTheme();
  const currentRoute = useLocation().pathname.split("/")[1];
  const { user } = useSelector((state) => state.auth);
  const userType = user && user.user && user.user.userType;
  const [isExpanded, setIsExpanded] = useState(false);
  const [Expanded, setExpanded] = useState(false);
  const handleDropdownToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDropdown = () => {
    setExpanded(!Expanded);
  };

  return (
    <Stack
      pl={2}
      sx={{
        height: "100vh",
 
        overflow:"hidden",
        py: 3,
        backgroundColor: theme.palette.primary.main,
      }}
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <Stack alignItems="flex-start" justifyContent="center" spacing={2} p={2}>
        <Stack style={{ marginLeft: "13px" }}>
          <SideBarLink
            Icon={
              currentRoute === "#" ? (
                <DashboardIcon />
              ) : (
                <DashboardIcon sx={{ color: "#fff" }} />
              )
            }
            title="Dashboard"
            link="DashBoard"
          />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            pl: 2,
            pr: 4,
            pt: 2,
            cursor: "pointer",
          }}
          onClick={handleDropdownToggle}
        >
          <Typography sx={{ color: "#fff" }}>{title}</Typography>
          <Box />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <SideBarLink
              Icon={
                currentRoute === "Customers" ? (
                  <Groups2 sx={{ color: "#fff" }} />
                ) : (
                  <Groups2Outlined sx={{ color: "#fff" }} />
                )
              }
              title="HireZap"
              link="Customers"
              onClick={handleDropdownToggle}
            />
          </Box>
          <Box sx={{ marginTop: "-8px" }}>
            {Icon}
            {isExpanded ? (
              <ExpandLess sx={{ color: "#fff" }} />
            ) : (
              <ExpandMore sx={{ color: "#fff" }} />
            )}
          </Box>
        </Box>
        {isExpanded && (
          <Box sx={{ pl: 5.5, cursor: "pointer" }}>
            <NavLink
              to="/HirezapPlans"
              style={{ textDecoration: "none" }}
              underline="hover"
            >
              {" "}
              <Typography sx={{ color: "white" }}>Plans</Typography>
            </NavLink>
          </Box>
        )}

       

<Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            pl: 2,
            pr: 4,
            pt: 2,
            cursor: "pointer",
          }}
          onClick={handleDropdown}
        >
          <Typography sx={{ color: "#fff" }}>{title}</Typography>
          <Box />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <SideBarLink
              Icon={
                currentRoute === "Customers" ? (
                  <Groups2 sx={{ color: "#fff" }} />
                ) : (
                  <Groups2Outlined sx={{ color: "#fff" }} />
                )
              }
              title="SkillZap"
              link="SkillZap"
              onClick={handleDropdown}
            />
          </Box>
          <Box sx={{ marginTop: "-8px" }}>
            {Icon}
            {Expanded ? (
              <ExpandLess sx={{ color: "#fff" }} />
            ) : (
              <ExpandMore sx={{ color: "#fff" }} />
            )}
          </Box>
        </Box>
        {Expanded && (
          <Box sx={{ pl: 5.5, cursor: "pointer" }}>
            <NavLink
              to="/skillzapPlans"
              style={{ textDecoration: "none" }}
              underline="hover"
            >
              {" "}
              <Typography sx={{ color: "white" }}>Analytics</Typography>
            </NavLink>
          </Box>
        )}
      </Stack>
   
    </Stack>
  );
};

export default DrawerS;
