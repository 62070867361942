import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PublicRoutes from "./publicRoutes";
import Users from "../views/Users/Users";
import Customers from "../views/Customer/Customers";
import Assessments from "../views/Assessments/Assessments";
import Applicants from "../views/Applicants/Applicants";
import CustomerDetails from "../views/Customer/CustomerDetails";
import Dashboard from "../views/Customer/Dashboard";
import Skillzap from "../views/Customer/Skillzap";
import HirezapPlans from "../views/Customer/HirezapPlans";
import SkillzapPlans from "../views/Customer/SkillzapPlans";
import ProtectedRoutes from "./privateRoutes";

const CLIENT_ID =
  "808094837942-9k3dm4cctpma0vk5dnmklnqvo94q0n9c.apps.googleusercontent.com";
const MainRoutes = () => (
  <GoogleOAuthProvider clientId={CLIENT_ID}>
    <Router>
      <Routes>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<Customers />} />
        </Route>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="/" />} />
          <Route path="/home" element={<Customers />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/users" element={<Users />} />
          <Route path="/Applicants" element={<Applicants />} />
          <Route path="/Assessments" element={<Assessments />} />
          <Route path="/CustomerDetails" element={<CustomerDetails />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Skillzap" element={<Skillzap />} />
          <Route path="/HirezapPlans" element={<HirezapPlans />} />
          <Route path="/SkillzapPlans" element={<SkillzapPlans />} />
        </Route>
      </Routes>
    </Router>
  </GoogleOAuthProvider>
);

export default MainRoutes;
