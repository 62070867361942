import * as actionTypes from "../actionTypes";

const initialState = {
  customers: [],
  customerdetails: [],
  skillzapusers: [],
  downloadusers: [],
  assessments: [],
  topassessments: [],
  dashboard: [],
  downloadassessments: [],
  downloadreport: [],
  completedBehavioral: [],
  signedupTechnical: [],
  signedupBehavioral: [],
  droppedTechnical: [],
  droppedBehavioral:[],
};
export default function CustomerReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CUSTOMERS:
      return { ...state, customers: action?.payload };
    case actionTypes.GET_SKILLZAPUSERS:
      return { ...state, skillzapusers: action.payload };
    case actionTypes.GET_ASSESSMENTS:
      return { ...state, assessments: action.payload };
    case actionTypes.GET_DASHBOARD:
      return { ...state, dashboard: action.payload };
    case actionTypes.DOWNLOAD_TOPASSESSMENTS:
      return { ...state, downloadassessments: action.payload };
    case actionTypes.DOWNLOAD_REPORT:
      return { ...state, downloadreport: action.payload };
    case actionTypes.COMPLETED_BEHAVIORAL:
      return { ...state, completedBehavioral: action.payload };
    case actionTypes.SIGNEDUP_TECHNICAL:
      return { ...state, signedupTechnical: action.payload };
    case actionTypes.SIGNEDUP_BEHAVIORAL:
      return { ...state, signedupBehavioral: action.payload };
    case actionTypes.DROPPED_TECHNICAL:
      return { ...state, droppedTechnical: action.payload };
      case actionTypes.DROPPED_BEHAVIORAL:
        return { ...state, droppedBehavioral: action.payload };
    case actionTypes.GET_TOPASSESSMENTS:
      return { ...state, topassessments: action?.payload };
    case actionTypes.DOWNLOAD_USERS:
      return { ...state, downloadusers: action.payload };
    case actionTypes.GET_USERS:
      return { ...state, skillzapusers: action.payload };
    case "SET_TABLE_STATE":
      return { ...state, ...action.payload };
    case actionTypes.GET_CUSTOMERDETAILS:
      return { ...state, customerdetails: action?.payload };
    case actionTypes.DOWNLOAD:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          download: action?.payload,
        },
      };
    case actionTypes.APPLICANT_CSV:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          download: action?.payload,
        },
      };
    case actionTypes.APPLICANTRESPONSE_CSV:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          download: action?.payload,
        },
      };
    case actionTypes?.ADDNOTES:
      return {
        ...state,
        customerdetails: { ...state.customerdetails, notes: action?.payload },
      };
    case actionTypes?.UPDATESEGMENT:
      return {
        ...state,
        customerdetails: {
          ...state.customerdetails,
          customer: {
            ...state.customerdetails.customer,
            segment: action?.payload?.segment,
          },
        },
      };
    case actionTypes?.UPDATEDATE:
      return {
        ...state,
        customerdetails: {
          ...state.customerdetails,
          customer: {
            ...state.customerdetails.customer,
            planEnd: action?.payload?.planEnd,
          },
        },
      };
    default:
      return state;
  }
}
