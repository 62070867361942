export const TRANSLATIONS_EN = {
  //AUTHPAGE
  sshome: "home",
  setPassword: " Set Your Password",
  EnterPassword: "Enter New Password",
  ConfirmPassword: "Confirm New Password",
  SendReset: "Send reset email",

  //CUSTOMERS
  search: "Search",
  sf: "Filter :",
  cn: "Customer Name",
  ct: "Created At",
  cp: "Current Plan",
  pe: "Plan Expiry",
  c_p: "",
  users: "Users",
  assessments: "Assessments",
  cdn: "Candidates",
  plan: "Current Plan",

  //Skillzap
  FirstName: "First Name",
  Email: "Email",
  ContactNo: "Contact No",
  CreatedAt: "Created At",
  Score: "Score",
  Status: "Status",
};
