/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Grid, Stack } from "@mui/material";
import axios from "axios";
import authImg from "../../images/auth.svg";
import { Google } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { googleSignIn } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const [message, setMessaage] = useState(false);
  const [error, setError] = useState("");

  const googleFaliure = (error) => {
    console.log("Google sign in was unsuccessful. Try again later");
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj; //cannot get property profileObj of undefined  undefinded
    const token = res?.tokenId;
    setError("");
    try {
      dispatch(
        googleSignIn(
          { ...result, authToken: token },
          setSaving,
          setMessaage,
          navigate
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const clientId =
    "808094837942-9k3dm4cctpma0vk5dnmklnqvo94q0n9c.apps.googleusercontent.com";

  const getUserData = async (token) => {
    const response = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          Accept: "application/json",
        },
      }
    );

    const userData = {
      email: response.data.email,
      googleId: response.data.id,
      authToken: token.access_token,
      name: response.data.name,
      givenName: response.data.given_name,
      familyName: response.data.family_name,
      imageUrl: response.data.picture,
    };
    dispatch(
      googleSignIn(
        { ...userData, googleId: "AUTO", authToken: "AUTO" },
        setSaving,
        setMessaage,
        navigate
      )
    );
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => getUserData(tokenResponse),
  });

  return (
    <Grid>
      <Stack
        alignItems="center"
        spacing={2}
        justifyContent="center"
        height="70vh"
      >
        <Grid height="400px" width="400px">
          <img
            src={authImg}
            alt="auth"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </Grid>
        <Stack>
          {/* <GoogleLogin
            clientId={clientId}
            render={(renderProps) => (
              <LoadingButton
                loading={saving}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                fullWidth
                startIcon={<Google />}
                variant="outlined"
              >
                Login with google
              </LoadingButton>
            )}
            onSuccess={googleSuccess}
            onFailure={googleFaliure}
            cookiePolicy={"single_host_origin"}
          /> */}
          {/* <Button
            onClick={login}
            color="google"
            fullWidth
            startIcon={<Google />}
            variant="outlined"
          >
           
            SignUp
          </Button> */}
          <Button onClick={login}>Sign in</Button>
        </Stack>
      </Stack>
    </Grid>
  );
};

export default Auth;
