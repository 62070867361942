import { createTheme } from "@mui/material";
import bp from "./breakPoints";

const primary = "#09337B";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: "#fff",
    },
    modal: {
      main: "#3A3A3A",
      contrastText: "#3A3A3A",
    },
    orange: {
      main: "#F54A20",
      contrastText: "#fff",
    },
    colors: {
      paper: "#F8F8F8",
      border: "#E8E8E8",
      border2: "#E1E1E1",
      white: "#ffffff",
      red: "#E3242B",
      blue: "#8BCFE4",
      card: "#F4F4F4",
      lightOrange: "#ffddcc",
      green: "#B5D261",
      green2: "#B2D157",
      lightOrange2: "#ffecd7",
      orangeLight: "#F5A278",
    },
    fonts: {
      white: "#ffffff",
      black: "#000000",
      sub: "#3A3A3A",
      inactive: "#CBCBCB",
      blue: "#1E6DB7",
      greenDark: "#3D5200",
      orangeDark: "#EB7941",
      lightOrange: "#ffddcc",
      capFade: "#c4c4c4",
      capLight: "#747474",
      capLight2: "#b2b7bb",
      cap: "#4e5159",
      orangeLight: "#F5A278",
      cap2: "#505050",
      sub2: "2c2c2c",
      title: "#3A3A3A",
      red: "#E3242B",
      title2: "#0a0600",
    },
    lightButton: {
      main: "#FFDDCC",
      contrastText: "#FF5400",
    },
  },

  typography: {
    textBold: {
      fontSize: 40,
      fontWeight: "700",
    },
    text1m: { fontSize: "0.7rem", fontWeight: 700 },
    text2B: { fontSize: 32, fontWeight: 700 },
    text2M: { fontSize: "28px", fontWeight: 600, color: "#3A3A3A" },
    text2N: { fontSize: "17px", fontWeight: 700 },
    text2C: { fontSize: "16px", fontWeight: 600 },
    text3M: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#3A3A3A",
      fontStyle: "Italic",
    },
    text4B: { fontSize: "22px", fontWeight: 550, color: "#3A3A3A" },
    text4N: { fontSize: "18px", fontWeight: 540, color: "#3A3A3A" },
    text4M: { fontSize: "25px", fontWeight: 550, color: "#3A3A3A" },
    text5M: { fontSize: 24, fontWeight: 600 },
    text5B: {
      fontSize: 24,
      fontWeight: 700,
      "@media(max-width: 450px)": {
        fontSize: 20,
      },
    },
    text6B: { fontSize: 18, fontWeight: 700 },
    text6M: { fontSize: "1.2rem", fontWeight: 600 },
    text6T: { fontSize: "18px", fontWeight: 400 },
    text6N: { fontSize: 18, fontWeight: 500 },
    text7M: { fontSize: "16px", fontWeight: 600 },
    text8M: { fontSize: "1.5rem", fontWeight: 600 },
    text8T: { fontSize: 14, fontWeight: 400 },
    text9T: { fontSize: 12, fontWeight: 400 },
    text9M: { fontSize: "1.8rem", fontWeight: 700, color: "#09337B" },
    text9B: { fontSize: 12, fontWeight: 700 },
    text10T: { fontSize: 11, fontWeight: 400 },
    text10M: { fontSize: 11, fontWeight: 600 },
    text11T: { fontSize: 10, fontWeight: 400 },
    text11M: { fontSize: 10, fontWeight: 600 },
    text11B: { fontSize: 10, fontWeight: 700 },
    text11UT: { fontSize: 10, fontWeight: 100 },
    text12M: { fontSize: 9, fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "22px",
          borderRadius: 8,
          fontWeight: 500,
          height: "44px",

          minWidth: "140px",
          "@media (max-width:1024px)": {
            minWidth: "110px",
            padding: "8px 10px",
          },
          "@media (max-width:450px)": {
            minWidth: "110px",
            height: "30px",
            padding: "6px 8px",
            fontSize: 10,
          },
        },

        sizeSmall: {
          fontSize: 14,
          fontWeight: 500,
          minWidth: "120px",
          padding: "5px 25px",
          [bp.breakpoints.between("md", "lg")]: {
            fontSize: 10,
          },
        },
      },
    },
  },
});

export default theme;
