import * as actionTypes from "../actionTypes";
import * as api from "../../API/index";
import { toast } from "react-toastify";

export const getCustomers = (setLoading, filter) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.Customers(filter);
    if (data) {
      dispatch({ type: actionTypes.GET_CUSTOMERS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const  getTopAssessments = (setLoading,data) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.topAssessments();
    
    if (data) {
      dispatch({ type: actionTypes.GET_TOPASSESSMENTS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};


export const getUsers = (setLoading, filter) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.skillZapUsers(filter);
    if (data) {
      dispatch({ type: actionTypes.GET_USERS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const getAssessments = (setLoading,id) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.getAssessments(id);
    if (data) {
      dispatch({ type: actionTypes.GET_ASSESSMENTS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const getDashboard = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.dashboard();
    if (data) {
      dispatch({ type: actionTypes.GET_DASHBOARD, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadTopAssessments = (setLoading,filter, startDate, endDate) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.downloadTopassessments(filter,startDate, endDate);
    if (data) {
      dispatch({ type: actionTypes.DOWNLOAD_TOPASSESSMENTS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadReport = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.downloadUsers();
    if (data) {
      dispatch({ type: actionTypes.DOWNLOAD_REPORT, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadBehavioralcompleted = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.completedBehavioral();
    if (data) {
      dispatch({ type: actionTypes.COMPLETED_BEHAVIORAL, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};
export const downloadSignedUpTechnical = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.signedupTechnical();
    if (data) {
      dispatch({ type: actionTypes.SIGNEDUP_TECHNICAL, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadSignedUpBehavioral = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.signedupBehavioral();
    if (data) {
      dispatch({ type: actionTypes.SIGNEDUP_BEHAVIORAL, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadAssessmentDroppedTechnical = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.assessmentdroppedTechnical();
    if (data) {
      dispatch({ type: actionTypes.DROPPED_TECHNICAL, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadAssessmentDroppedBehavioral = (setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.assessmentdroppedBehavioral();
    if (data) {
      dispatch({ type: actionTypes.DROPPED_BEHAVIORAL, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

export const downloadUsers = (setLoading, filter) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.downloadskillZapUsers(filter);
    if (data) {
      dispatch({ type: actionTypes.DOWNLOAD_USERS, payload: data });
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
  }
  setLoading(false);
};

export const setCustomers = (values) => (dispatch) => {
  dispatch({ type: actionTypes.GET_CUSTOMERS, payload: values });
};

export const getCustomerDetails = (id, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.getCustomerDetails(id);
    if (data) {
      dispatch({ type: actionTypes.GET_CUSTOMERDETAILS, payload: data });
      setLoading(false);
    }
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};
export const updateCustomerSegment =
  (id, segment, setLoading, setOpen) => async (dispatch) => {
    setLoading(true);
    try {
      const { data } = await api.updateSegment(id, segment);
      if (data) {
        dispatch({
          type: actionTypes.UPDATESEGMENT,
          payload: segment,
        });
        setLoading(false);
        setOpen(false);
      }
      toast.success(data?.success);
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error?.response?.data,
      });
      setLoading(false);
    }
  };

export const updateCustomerDate =
  (id, planEnd, setLoading) => async (dispatch) => {
    setLoading(true);
    try {
      const { data } = await api.updateDate(id, planEnd);
      if (data) {
        dispatch({
          type: actionTypes.UPDATEDATE,
          payload: planEnd,
        });
        setLoading(false);
      }
      toast.success(data?.success);
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error?.response?.data,
      });
      setLoading(false);
    }
  };

export const addNotes = (id, note, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data } = await api.addNotes(id, note);
    if (data) {
      dispatch({ type: actionTypes?.ADDNOTES, payload: data });
    }
    setLoading(false);
  } catch (error) {
    toast.error(error?.response?.data?.error);
    dispatch({ type: actionTypes.SET_ERRORS, payload: error?.response?.data });
    setLoading(false);
  }
};

// export const applicantCsv = (
// customerId,
// jobId,
// custName,
// jobName,
// applicants,
// setLoading
// ) => {
//   return async (dispatch) => {
//     try {
//       setLoading(true);
// const response = await api.applicantCsv(
//   customerId,
//   jobId,
//   custName,
//   jobName,
//   applicants
// );
//       if (response && response.data) {
//         const url = window.URL.createObjectURL(
//           new Blob([response.data], { type: "text/csv;charset=utf-8" })
//         );
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", "applicant_report.csv");
//         document.body.appendChild(link);
//         link.click();
//         setLoading(false);
//         dispatch({
//           type: actionTypes.APPLICANT_CSV,
//           payload: response.data,
//         });
//         return Promise.resolve(null);
//       } else {
//         setLoading(false);
//         dispatch({ type: actionTypes.APPLICANT_CSV, payload: null });
//         return Promise.reject(
//           new Error("No data available for the specified jobId")
//         );
//       }
//     } catch (error) {
//       setLoading(false);
//       console.log("Error fetching applicant CSV:", error);
//       const errorMsg =
//         error?.response?.data?.error ||
//         "An error occurred while fetching the applicant CSV";
//       toast.error(errorMsg);
//       dispatch({
//         type: actionTypes.SET_ERRORS,
//         payload: error?.response?.data || { error: errorMsg },
//       });
//       return Promise.reject(error);
//     }
//   };
// };

// export const applicantResponseCsv = (jobId, custName, jobName, setLoading) => {
//   return async (dispatch) => {
//     try {
//       setLoading(true);
//       const response = await api.applicantResponseCsv(jobId, custName, jobName);
//       if (response && response.data) {
//         const url = window.URL.createObjectURL(
//           new Blob([response.data], { type: "text/csv;charset=utf-8" })
//         );
//         const link = document.createElement("a");
//         link.href = url;
//         link.setAttribute("download", "applicant_report.csv");
//         document.body.appendChild(link);
//         link.click();
//         setLoading(false);
//         dispatch({
//           type: actionTypes.APPLICANTRESPONSE_CSV,
//           payload: response.data,
//         });
//         return Promise.resolve(null);
//       } else {
//         setLoading(false);
//         dispatch({ type: actionTypes.APPLICANTRESPONSE_CSV, payload: null });
//         return Promise.reject(
//           new Error("No data available for the specified jobId")
//         );
//       }
//     } catch (error) {
//       setLoading(false);
//       console.log("Error fetching applicant CSV:", error);
//       const errorMsg =
//         error?.response?.data?.error ||
//         "An error occurred while fetching the applicant CSV";
//       toast.error(errorMsg);
//       dispatch({
//         type: actionTypes.SET_ERRORS,
//         payload: error?.response?.data || { error: errorMsg },
//       });
//       return Promise.reject(error);
//     }
//   };
// };

export const download =
  (custId, jobId, custName, jobName) => async (dispatch) => {
    try {
      const { data } = await api.download(custId, jobId, custName, jobName);
      if (data) {
        const filename = `${custName}_${jobName}.csv`;
        dispatch({ type: actionTypes.DOWNLOAD, payload: data, filename });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error?.response?.data,
      });
    }
  };

export const applicantCsv =
  (customerId, jobId, custName, jobName, applicants, setLoading) =>
  async (dispatch) => {
    try {
      const { data } = await api.applicantCsv(
        customerId,
        jobId,
        custName,
        jobName,
        applicants,
        setLoading
      );
      if (data) {
        const filename = `${custName}_${jobName}_${applicants}.csv`;
        dispatch({ type: actionTypes.APPLICANT_CSV, payload: data, filename });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error?.response?.data,
      });
    }
  };

export const applicantResponseCsv =
  (jobId, custName, jobName, applicants, setLoading) => async (dispatch) => {
    try {
      const { data } = await api.applicantResponseCsv(
        jobId,
        custName,
        jobName,
        applicants,
        setLoading
      );
      if (data) {
        const filename = `${custName}_${jobName}.csv`;
        dispatch({
          type: actionTypes.APPLICANTRESPONSE_CSV,
          payload: data,
          filename,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      dispatch({
        type: actionTypes.SET_ERRORS,
        payload: error?.response?.data,
      });
    }
  };
