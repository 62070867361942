import {
  Grid,
  Paper,
  Stack,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Modal,
  Button,
  Collapse,
} from "@mui/material";
import { Add as AddIcon, Remove as MinusIcon } from "@material-ui/icons";
import React from "react";
import { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LargeLoading from "../../components/utils/largeLoading";
import {
  getCustomerDetails,
  updateCustomerSegment,
  updateCustomerDate,
  download,
  applicantCsv,
  applicantResponseCsv,
} from "../../store/actions/CustomerActions";
import { EditOutlined } from "@mui/icons-material";
import AddNotes from "./addNotes";

const box = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  marginTop: "12px",
};
const initialValues = {
  _id: "",
  planEnd: "",
  segment: "",
  assessmentId: "",
  customerId: "",
};

const CustomerDetails = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const boxStyle = {
    width: 150,
    height: 70,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "primary.main",
      opacity: [0.9, 0.8, 0.7],
      cursor: "pointer",
    },
  };

  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(initialValues);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState({});
  const open = Boolean(anchorEl);
  const [openi, setOpen] = useState(false);
  const [setSegment] = useState(""); // set initial value to 'Target'
  const id = open ? "simple-popover" : undefined;
  const [selectedSegment, setSelectedSegment] = useState("");
  const { user } = useSelector((state) => state.auth);

  const [collapsedJobId, setCollapsedJobId] = useState(null);

  const currentUserEmail = user?.user?.email;
  const allowedEmails = [
    "abhijeet.prakash@ipl.edu.in",
    "tarun.agarwala@ipl.edu.in",
    "abhijeet@zapilio.com",
    "tarun@zapilio.com"
  ];

  const handleToggleCollapse = (jobId) => {
    if (collapsedJobId === jobId) {
      setCollapsedJobId(null);
    } else {
      setCollapsedJobId(jobId);
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  const { customerdetails } = useSelector((state) => state?.customer);

  const handleSegment = (segment, customer) => {
    dispatch(
      updateCustomerSegment(
        customer?._id,
        { segment: segment }, // Use the selectedSegment value
        setUpdating,
        setOpen
      )
    );
  };

  const handleSegmentChange = (event) => {
    setSegment(event.target.value); // update segment state with the selected value
  };

  useEffect(() => {
    if (customerdetails?.customer?._id) {
      setFormValues({
        _id: customerdetails?.customer?._id,
        planEnd: customerdetails?.customer?.planEnd,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerdetails?.customer]);

  useEffect(() => {
    if (customerdetails?.customer?._id) {
      setFormValues({
        _id: customerdetails?.customer?._id,
        segment: customerdetails?.customer?.segment,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerdetails?.customer]);
  useEffect(() => {
    dispatch(getCustomerDetails(state, setLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    dispatch(updateCustomerDate(state, formValues, setUpdating));
  };
  const customerid = customerdetails?.customer?._id;
  const jobName = customerdetails?.jobs?.map((a, i) => a.package);
  const custName = customerdetails?.customer?.name;

  const handleDownload = (customerId, jobId, custName) => {
    const job = customerdetails?.jobs.find((j) => j._id === jobId._id);
    const jobName = job?.package;
    dispatch(download(customerId, jobId._id, custName, jobName));
  };

  const handleDownloadApplicantCsv = (customerId, jobId, custName) => {
    const job = customerdetails?.jobs.find((j) => j._id === jobId);
    const jobName = job?.package;
    const applicants = job?.applicants;
    dispatch(
      applicantCsv(customerId, jobId, custName, jobName, applicants, setLoading)
    );
  };

  const handleDownloadApplicantResponseCsv = (jobId) => {
    const job = customerdetails?.jobs.find((j) => j._id === jobId);
    const jobName = job?.package;
    const applicants = job?.applicants;
    dispatch(
      applicantResponseCsv(
        jobId,
        customerdetails?.customer?.name,
        jobName,
        applicants,
        setLoading
      )
    );
  };

  const handleEdit = (e, a) => {
    setData({});
    setAnchorEl(e.currentTarget);
    setData(a);
  };

  return (
    <>
      {" "}
      {loading ? (
        <LargeLoading />
      ) : (
        <Grid
          sx={{
            width: "100%",
            height: "100vh",
            background: "#F7F9FD",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ cursor: "pointer" }}
            onClick={handleBack}
          >
            <ArrowBackIcon></ArrowBackIcon>
            <Stack>Back</Stack>
          </Stack>
          <Stack pb={2}>
            <Typography variant="text4N">{t("c_p")}</Typography>
          </Stack>
          <AddNotes
            id={id}
            data={data}
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />

          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: {
                sm: "column",
                xs: "column",
                lg: "row",
                md: "row",
              },
            }}
            mb={2}
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              display="flex"
              justifyContent="space-between"
              sx={{
                flexDirection: {
                  sm: "column",
                  xs: "column",
                  lg: "row",
                  md: "row",
                },
              }}
            >
              <img
                src={customerdetails?.customer?.logoURL}
                alt="bg"
                style={{
                  height: "50px",
                  borderRadius: "6px",
                  width: "120px",
                }}
              />
              <Stack direction="column">
                <Typography variant="text4N">
                  {customerdetails.customer?.name}
                </Typography>

                <Typography variant="text4D">
                  {t("ct")}: {"    "}
                  {moment(customerdetails.customer?.createdAt).format(
                    "YYYY-MM-DD"
                  )}
                </Typography>
                <Typography variant="text4D">
                  {t("plan")}:{customerdetails.customer?.plan}
                </Typography>
              </Stack>
              <FormControl
                variant="standard"
                sx={{ minWidth: 150 }}
                size="small"
              >
                <InputLabel id="demo-select-small">{t("Segment")}</InputLabel>
                <Select
                  disabled={updating}
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue={""}
                  label="Status"
                  onClick={() => setOpen(true)}
                  value={formValues.segment}
                  onBlur={handleSegmentChange}
                  onChange={(e) => setSelectedSegment(e.target.value)}
                >
                  {[
                    "Target",
                    "Non Target",
                    "Design Partner",
                    "Internal",
                    "Ignore",
                  ].map((h, i) => (
                    <MenuItem value={h} key={i}>
                      {h}
                    </MenuItem>
                  ))}
                </Select>
                <Modal
                  open={openi}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "500px",
                      height: "302px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 8px 16px #00000014",
                      borderRadius: "16px",
                      opacity: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Are you sure you want to change the segment ?
                    </Typography>
                    <Stack direction="row" alignItems="center" gap={2} m={5}>
                      <Button
                        variant="contained"
                        id="demo-select-small"
                        defaultValue={""}
                        label="Status"
                        onClick={() =>
                          handleSegment(
                            selectedSegment,
                            customerdetails?.customer
                          )
                        }
                        sx={{
                          width: "98px",
                          height: "44px",
                          fontSize: "18px",
                          background: "#09337B 0% 0% no-repeat padding-box",
                          borderRadius: " 4px",
                          opacity: 1,
                        }}
                        onChange={(e) =>
                          handleSegment(e, customerdetails?.customer)
                        }
                      >
                        Yes
                      </Button>
                      <Button
                        onClick={() => setOpen(false)}
                        variant="contained"
                        sx={{
                          width: "98px",
                          height: "44px",
                          fontSize: "18px",
                          border: "1px solid #09337B",
                          borderRadius: " 4px",
                          opacity: 1,
                        }}
                      >
                        No
                      </Button>
                    </Stack>
                  </Box>
                </Modal>
              </FormControl>

              <LocalizationProvider size="small" dateAdapter={AdapterDayjs}>
                <DatePicker
                  p={2}
                  disabled={updating}
                  disablePast={true}
                  size="small"
                  name="planEnd"
                  type="planEnd"
                  value={
                    moment(formValues.planEnd).format("YYYY-MM-DDTHH:mm:ssZ") ||
                    null
                  }
                  onChange={(newValue) => {
                    console.log(newValue);
                    setFormValues({ ...formValues, planEnd: newValue.$d });
                  }}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                  componentsProps={{
                    actionBar: { actions: ["cancel", "accept"] },
                  }}
                  label="Extend Plan"
                  closeOnSelect={false}
                  onAccept={handleSubmit}
                />
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" sx={{ mt: { xs: "3px" } }} spacing={4} p={1}>
              <Box sx={boxStyle}>
                <Typography variant="text2C" sx={box}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack>{t("users")}</Stack>
                    <Stack>{customerdetails.users?.length}</Stack>
                  </Stack>
                </Typography>
              </Box>

              <Box sx={boxStyle}>
                <Typography variant="text2C" sx={box}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack>{t("assessments")}</Stack>
                    <Stack>{customerdetails.jobs?.length}</Stack>
                  </Stack>
                </Typography>
              </Box>
              <Box sx={boxStyle}>
                <Typography variant="text2C" sx={box}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack>{t("cdn")}</Stack>
                    <Stack>
                      {customerdetails.jobs?.reduce(function (prev, current) {
                        return prev + +current.applicants;
                      }, 0)}
                    </Stack>
                  </Stack>
                </Typography>
              </Box>
            </Stack>
          </Grid>
          <>
            <Paper
              sx={{
                width: "100%",
                height: "70px",
                borderRadius: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 30px",
                display: "grid",
                gridTemplateColumns: "1.5fr repeat(3, 1fr)",
                marginBottom: "10px",
                border: 0.1,
                backgroundColor: "	#E6E6FA",
              }}
            >
              <Typography variant="text6B">Name</Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Email
              </Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Created
              </Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Type
              </Typography>
            </Paper>
            <>
              {customerdetails &&
                customerdetails.users &&
                customerdetails.users.map((a, i) => (
                  <TableContainer key={i}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            display: "grid",
                            gridTemplateColumns: "1.4fr repeat(3, 1fr)",
                            padding: "15px 30px",
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "18px",
                              color: "#09337B",
                              cursor: "pointer",
                              minWidth: "120px",
                            }}
                          >
                            {a?.displayName}
                          </TableCell>

                          <TableCell sx={{ fontSize: "18px" }}>
                            {" "}
                            {a?.email}
                          </TableCell>
                          <TableCell sx={{ fontSize: "18px" }}>
                            {moment(a?.createdAt).format("DD-MMM-YYYY h:mm")}
                          </TableCell>
                          <TableCell sx={{ fontSize: "18px" }}>
                            {a?.userType}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Divider />
                  </TableContainer>
                ))}
            </>
          </>
          <Paper
            sx={{
              width: "100%",
              height: "70px",
              borderRadius: "5px",

              padding: "15px 30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
              border: 1,
              background: "#FFFFFF 0% 0% no-repeat padding-box",

              backgroundColor: "	#E6E6FA",
            }}
          >
            <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
              Notes
            </Typography>
            <IconButton
              onClick={(e) => handleEdit(e, customerdetails?.customer)}
            >
              <EditOutlined />
            </IconButton>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              borderRadius: "5px",
              gap: "10px",
              marginBottom: "10px",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 20px #0000001A",
            }}
          >
            <Stack divider={<Divider />}>
              {customerdetails?.notes?.map((n, i) => (
                <Stack
                  p={2}
                  key={i}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="text7M">{n?.note}</Typography>
                  <Typography variant="text7M">
                    {n?.user?.displayName}
                  </Typography>
                  <Typography variant="text7M">
                    {moment(n?.createdAt).format("YYYY-MM-DD , h:mm:ss a")}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Paper>
          <>
            <Paper
              sx={{
                width: "100%",
                height: "70px",
                borderRadius: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 30px",
                display: "grid",
                gridTemplateColumns: " 2fr repeat(3, 1fr)",
                marginBottom: "10px",
                border: 1,
                background: "#FFFFFF 0% 0% no-repeat padding-box",

                backgroundColor: "	#E6E6FA",
              }}
            >
              <Typography
                variant="text6B"
                sx={{ color: "#3a3a3a", marginLeft: "30px" }}
              >
                Package Name
              </Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Status
              </Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Applicants
              </Typography>
              <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
                Created
              </Typography>
            </Paper>
            <>
              {customerdetails &&
                customerdetails.jobs &&
                customerdetails.jobs.map((val, i) => (
                  <TableContainer key={i}>
                    <Table aria-label="simple table">
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            display: "grid",
                            gridTemplateColumns: "2fr repeat(3, 1fr)",
                            padding: "15px 30px",
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "18px",
                              color: "#09337B",
                              cursor: "pointer",
                              minWidth: "120px",
                            }}
                          >
                            <Stack direction="column">
                              <Stack direction="row" alignItems="center">
                                <Stack direction="row" alignItems="center">
                                  {val.applicants > 0 && (
                                    <IconButton
                                      onClick={() =>
                                        handleToggleCollapse(val._id)
                                      }
                                    >
                                      {collapsedJobId === val._id ? (
                                        <MinusIcon />
                                      ) : (
                                        <AddIcon />
                                      )}
                                    </IconButton>
                                  )}

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {val.package}
                                    {val.applicants !== 0 ? (
                                      <DownloadIcon
                                        onClick={() =>
                                          handleDownload(
                                            customerid,
                                            val,
                                            custName,
                                            jobName
                                          )
                                        }
                                        style={{ marginLeft: "5px" }}
                                      />
                                    ) : null}
                                  </div>
                                </Stack>
                              </Stack>
                              {val.applicants > 0 && (
                                <Collapse
                                  in={collapsedJobId === val._id}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <div style={{ padding: "10px" }}>
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Applicant Scores
                                      <DownloadIcon
                                        sx={{
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() =>
                                          handleDownloadApplicantCsv(
                                            customerdetails?.customer?._id,
                                            val._id,
                                            custName,
                                            jobName
                                          )
                                        }
                                      />
                                    </p>
                                    {allowedEmails.includes(
                                      currentUserEmail
                                    ) && (
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        Applicant Detail Responses{" "}
                                        <DownloadIcon
                                          sx={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleDownloadApplicantResponseCsv(
                                              val._id
                                            )
                                          }
                                        />
                                      </p>
                                    )}
                                  </div>
                                </Collapse>
                              )}
                            </Stack>
                          </TableCell>

                          <TableCell sx={{ fontSize: "18px" }}>
                            {val.status}
                          </TableCell>
                          <TableCell sx={{ fontSize: "18px" }}>
                            {val.applicants}
                          </TableCell>
                          <TableCell sx={{ fontSize: "18px" }}>
                            {moment(val.createdAt).format("YYYY-MM-DD ")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Divider />
                  </TableContainer>
                ))}
            </>
          </>
        </Grid>
      )}
    </>
  );
};

export default CustomerDetails;
