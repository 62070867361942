import React from "react";
import { Stack, CircularProgress } from "@mui/material";

const LargeLoading = () => {
  return (
    <Stack sx={{ height: "70vh" }} alignItems="center" justifyContent="center">
      <CircularProgress size="150px" />
    </Stack>
  );
};

export default LargeLoading;
