import * as actionTypes from "../actionTypes";
import * as api from "../../API/index";

import { toast } from "react-toastify";

export const logout = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SIGNOUT });
    sessionStorage.clear();
    navigate("/auth");
  } catch (error) {
    toast.error(error?.response?.data?.error);
  }
};
export const googleSignIn = (form, setLoading, setMessage, navigate) => async (dispatch) => {
  try {
    setLoading(true);
    const { data } = await api.googleSignIn({ ...form, url: JSON.parse(sessionStorage.getItem("referral")) });
    if (data) {
      sessionStorage.setItem("user", JSON.stringify(data));
      dispatch({ type: actionTypes.SIGNIN, payload: data });
      setLoading(false);
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERRORS, payload: error.response?.data });
    toast.error(error?.response?.data?.error);
    setMessage(error.response?.data);
    setLoading(false);
  }
};
