import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import dayjs from "dayjs";
import { DialogActions } from "@material-ui/core";
import { downloadTopAssessments } from "../../store/actions/CustomerActions";
import { format } from "date-fns";
import { Stack, Box, Grid, TextField, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../../store/actions/CustomerActions";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Label } from "recharts";
import { DesktopDatePicker, DateRangePicker } from "@mui/x-date-pickers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const initialfilter = {
  days: "",
};

const UserCount = () => {
  //UserCount
  const dashboard = useSelector((state) => state.customer?.dashboard);
  const [filter, setFilter] = useState(initialfilter);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState("5");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [customDatepickerOpen, setCustomDatepickerOpen] = useState(false);
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const handleStartDateFilter = (date) => {
    setStartDate(date);
  };
  const handleCustomDateFilter = () => {
    setCustomDatepickerOpen(false);
    setIsDateRangeSelected(true);
    setStartDate(customStartDate);
    setEndDate(customEndDate);
  };

  const handleChange = (event) => {
    event.stopPropagation();
    if (event.target.value === "custom") {
      setIsDateRangeSelected(true);
      setCustomDatepickerOpen(true); // open custom datepicker
    } else {
      setIsDateRangeSelected(false);
      setRange(event.target.value);
      setStartDate(null);
      setEndDate(null);
      setCustomStartDate(null);
      setCustomEndDate(null);
    }
  };

  const handleEndDateFilter = (date) => {
    setEndDate(date);
  };

  

  useEffect(() => {
    dispatch(getDashboard(setLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const data = dashboard?.users || [];

  const filteredData = useMemo(() => {
    let filtered = data;

    if (startDate && endDate) {
      filtered = filtered.filter((item) => {
        const date = new Date(item._id);
        return date >= new Date(startDate) && date <= new Date(endDate);
      });
    } else if (range !== "all" && range !== "custom") {
      // add condition to exclude "custom" value
      filtered = filtered.slice(-parseInt(range));
    }

    return filtered;
  }, [data, range, startDate, endDate]);

  const CustomXAxisTick = ({ x, y, payload }) => {
    return (
      <text x={x} y={y + 10} textAnchor="middle" fill="#666">
        {format(new Date(payload.value), "MM/dd")}
      </text>
    );
  };

  function format(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix =
      day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";
    return `${day}${suffix}`;
  }

  const totalUsersCount = filteredData.reduce(
    (sum, item) => sum + item.totalUsers,
    0
  );

  return (
    <Grid item xs={12} sm={6}>
      <Typography
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ fontSize: "18px" }}
      >
        User Count
      </Typography>
      <Grid container direction="row">
        <Grid item>
          <FormControl style={{ marginLeft: "80px" }}>
            <InputLabel htmlFor="range"> Date Range</InputLabel>
        
            <Select
  value={range}
  inputProps={{
    name: "range",
    id: "range",
  }}
  onChange={handleChange}
  onClick={(event) => {
    event.stopPropagation();
    if (event.target.value === "custom") {
      setCustomDatepickerOpen(true);
      setRange("custom");
    }
  }}
>
  <MenuItem value="2">Last 2 Days</MenuItem>
  <MenuItem value="5">Last 5 Days</MenuItem>
  <MenuItem value="7">Last 1 Week</MenuItem>
  <MenuItem value="14">Last 2 Week</MenuItem>
  <MenuItem
  value="custom"
  onClick={(event) => {
    event.stopPropagation(); // prevent triggering the first Select component
    setRange("custom"); // set the selectedRange to "custom"
  }}
>
  Custom&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</MenuItem>

</Select>


            <Dialog
              open={customDatepickerOpen}
              onClose={() => setCustomDatepickerOpen(false)}
            >
              <DialogContent>
                <Grid container direction="column">
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        size="small"
                        inputFormat="YYYY/MM/DD "
                        label="Start Date"
                        maxDate={new Date()}
                        value={customStartDate}
                        onChange={(date) => setCustomStartDate(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        onClose={() => {}}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid textAlign="center">to</Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        size="small"
                        inputFormat="YYYY/MM/DD "
                        label="End Date"
                        maxDate={new Date()}
                        value={customEndDate}
                        onChange={(date) => setCustomEndDate(date)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        onClose={() => {}}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions style={{ marginRight: "60px" }}>
                <Button onClick={handleCustomDateFilter}>Ok</Button>
              </DialogActions>
            </Dialog>
          </FormControl>
        </Grid>
        <Grid item style={{ marginLeft: "220px", marginTop: "10px" }}>
          {" "}
          Total Users Count: &nbsp;{totalUsersCount}
        </Grid>
      </Grid>
      <ResponsiveContainer height={300} width={600}>
        <AreaChart
          data={filteredData.sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )}
          width={600}
          height={400}
          margin={{ top: 25, right: 20, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="_id"
            tick={<CustomXAxisTick />}
            interval={0}
            label={{
              value: "",
              position: "insideBottom",
              offset: -10,
              interval: 5,
            }}
            padding={{ left: 20, top: 20, right: 20, bottom: 20 }}
            axisLine={{ stroke: "#999999" }}
            hide
          />
          <YAxis
            label={{
              value: "No of Users",
              angle: -90,
              dx: -20,
              position: "insideCenter",
              offset: -30,
            }}
          />
          <Tooltip />
          <Area
            dataKey="totalUsers"
            fill="#CC5500"
            dot={{ stroke: "#CC5500", strokeWidth: 2 }}
            label={({ x, y, value, index }) => (
              <text x={x} y={y} dy={-10} fontSize={14} textAnchor="middle">
                {value}
              </text>
            )}
            connectNulls={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Grid>
  );
};
export default UserCount;
