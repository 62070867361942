import { Grid, Paper, Typography, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import GroupDelete from "../../images/GroupDelete.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const HirezapPlans = () => {
  const { t } = useTranslation();
  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "70px",
          borderRadius: "5px",
          padding: "15px 30px",
          display: "grid",
          gridTemplateColumns: "1.5fr repeat(4, 1fr)",
          marginBottom: "10px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 20px #0000001A",
        }}
      >
        <Typography variant="text6B" sx={{ marginLeft: "30px" }}>
          {t("Plans")}
        </Typography>
        <Typography variant="text6B" sx={{ color: "#3a3a3a" }}></Typography>
        <Typography variant="text6B" sx={{ color: "#3a3a3a" }}></Typography>
        <Typography variant="text6B" sx={{ color: "#3a3a3a" }}></Typography>

        <></>
      </Paper>
      <>
        <Paper
          sx={{
            width: "100%",
            borderRadius: "5px",
            gap: "10px",
            marginBottom: "10px",

            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 20px #0000001A",
          }}
        >
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    display: "grid",
                    gridTemplateColumns: "0.1fr repeat(6, 1fr)",
                    padding: "15px 30px",
                  }}
                >
                  <TableCell>
                    <Stack direction="row">
                      <Checkbox {...label} />
                      <Typography sx={{ marginTop: "10px" }}>
                        {t("Name ")}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "18px",
                      color: "#09337B",
                      cursor: "pointer",
                      minWidth: "120px",
                    }}
                  >
                    {" "}
                  </TableCell>

                  <TableCell sx={{ fontSize: "18px" }}></TableCell>
                  <TableCell sx={{ fontSize: "18px" }}></TableCell>
                  <TableCell sx={{ marginLeft: "450px" }}>
                    <Stack direction="row" spacing={1}>
                      <AddCircleIcon
                        variant="outlined"
                        style={{ color: "#00008B", cursor: "pointer" }}
                      />
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 450,
                          cursor: "pointer",
                        }}
                      >
                        Add
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ fontSize: "18px" }}>
                    {" "}
                    <Stack direction="row" spacing={1}>
                      <img
                        src={GroupDelete}
                        alt=""
                        style={{
                          color: "#fff",
                          width: "25px",
                          cursor: "pointer",
                        }}
                      />
                      <Typography
                        color="primary"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 450,
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    </>
  );
};

export default HirezapPlans;
