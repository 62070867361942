import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Add as AddIcon, Remove as MinusIcon } from "@material-ui/icons";
import { Paper, Typography,Collapse,Stack,IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Download, DownloadOutlined } from "@mui/icons-material";
import Moment from "react-moment";
import { getAssessments } from "../../store/actions/CustomerActions";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Divider } from "@mui/material";




export default function UsersTable({ data = { docs: [] } }, assessment ) {
  console.log(data?.docs.map((a)=>a._id))
  const dispatch = useDispatch();
  const[loading,setLoading]=useState();

  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField1, setSortField1] = useState("");
  const [sortOrder1, setSortOrder1] = useState("asc");
  const [sortField2, setSortField2] = useState("");
  const [sortOrder2, setSortOrder2] = useState("asc");
  const [sortField3, setSortField3] = useState("");
  const [sortOrder3, setSortOrder3] = useState("asc");
  const [collapsedJobId, setCollapsedJobId] = useState(null);
  const assessments = useSelector((state) => state.customer?.assessments);
  console.log(assessments);
  const handleToggleCollapse = (jobId) => {
    if (collapsedJobId !== jobId) {
      setCollapsedJobId(jobId);
      if (!assessments[jobId]) {
        dispatch(getAssessments(setLoading, jobId));
      }
    } else {
      setCollapsedJobId(null);
    }
  };

  const [selectedUserAssessments, setSelectedUserAssessments] = useState([]);

useEffect(() => {
  if (collapsedJobId) {
    dispatch(getAssessments(setLoading, collapsedJobId)).then((res) => {
      setSelectedUserAssessments(res);
    });
  }
}, [dispatch, collapsedJobId]);
const handleDownloadReport = () => {
  const url = assessment.reportURL;
  const fileName = "report.pdf";
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
};

const downloadReport = (e, url) => {
  console.log("clicked");
  e.preventDefault();
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'assessment_report.pdf');
      document.body.appendChild(link);
      link.click();
    });
};


  const { t } = useTranslation();
  const handleSort = (field) => {
    let order;
    if (sortField === field) {
      order = sortOrder === "asc" ? "desc" : "asc";
    } else {
      order = "asc";
      // Reset state of other sorting fields
      setSortField1("");
      setSortOrder1("asc");
    }
    setSortField(field);
    setSortOrder(order);
  };

  const handleSort1 = (field) => {
    let order;
    if (sortField1 === field) {
      order = sortOrder1 === "asc" ? "desc" : "asc";
    } else {
      order = "asc";
      // Reset state of other sorting fields
      setSortField("");
      setSortOrder("asc");
      setSortField2("");
      setSortOrder2("asc");
      setSortField3("");
      setSortOrder3("asc");
    }
    setSortField1(field);
    setSortOrder1(order);
  };

  const handleSort2 = (field) => {
    let order;
    if (sortField2 === field) {
      order = sortOrder2 === "asc" ? "desc" : "asc";
    } else {
      order = "asc";
      // Reset state of other sorting fields
      setSortField("");
      setSortOrder("asc");
    }
    setSortField2(field);
    setSortOrder2(order);
  };

  const handleSort3 = (field) => {
    let order;
    if (sortField3 === field) {
      order = sortOrder3 === "asc" ? "desc" : "asc";
    } else {
      order = "asc";
      // Reset state of other sorting fields
      setSortField2("");
      setSortOrder2("asc");
    }
    setSortField3(field);
    setSortOrder3(order);
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "70px",
          borderRadius: "5px",
          padding: "15px 30px",
          display: "grid",
          gridTemplateColumns: "1.0fr 1.5fr 1.0fr 1.2fr 1.0fr",
          marginBottom: "10px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 3px 20px #0000001A",
        }}
      >
        <Typography
          variant="text6B"
          onClick={() => handleSort("displayName")}
          sx={{
            color: sortField === "displayName" ? "#3a3a3a" : "#3a3a3a",
            cursor: "pointer",
          }}
        >
          {t("Name")}
          <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
        </Typography>
        <Typography
          variant="text6B"
          onClick={() => handleSort1("email")}
          sx={{
            color: sortField1 === "email" ? "#3a3a3a" : "#3a3a3a",
            cursor: "pointer",
          }}
        >
          {t("Email")}
          <span>{sortOrder1 === "asc" ? " ▲" : " ▼"}</span>
        </Typography>

        <Typography
          variant="text6B"
          onClick={() => handleSort2("createdAt")}
          sx={{
            color: sortField2 === "createdAt" ? "#3a3a3a" : "#3a3a3a",
            cursor: "pointer",
          }}
        >
          {t("Created At")}
          <span>{sortOrder2 === "asc" ? " ▲" : " ▼"}</span>
        </Typography>
        <Typography
          variant="text6B"
          onClick={() => handleSort3("eduCom")}
          sx={{
            color: sortField3 === "eduCom" ? "#3a3a3a" : "#3a3a3a",
            cursor: "pointer",
          }}
        >
          {t("Education Institute / Organization")}
          <span>{sortOrder3 === "asc" ? " ▲" : " ▼"}</span>
        </Typography>
        <Typography variant="text6B">
          {" "}
          {t("Assessment Completed Count")}
        </Typography>
      </Paper>
      {data.docs
        .sort((a, b) => {
          if (sortField === "displayName") {
            if (sortOrder === "asc") {
              return a.displayName.localeCompare(b.displayName);
            } else {
              return b.displayName.localeCompare(a.displayName);
            }
          } else if (sortField2 === "createdAt") {
            if (sortOrder2 === "asc") {
              return a.createdAt.localeCompare(b.createdAt);
            } else {
              return b.createdAt.localeCompare(a.createdAt);
            }
          } else if (sortField3 === "eduCom") {
            if (sortOrder3 === "asc") {
              return (a.eduCom || "").localeCompare(b.eduCom || "");
            } else {
              return (b.eduCom || "").localeCompare(a.eduCom || "");
            }
          } else if (sortField1 === "email") {
            if (sortOrder1 === "asc") {
              return a.email.localeCompare(b.email);
            } else {
              return b.email.localeCompare(a.email);
            }
          } else {
            return 0;
          }
        })
        .map((CustomerData, i) => (
          <TableContainer key={i}>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    display: "grid",
                    gridTemplateColumns: "1.0fr 1.5fr 1.0fr 1.2fr 1.0fr",
                    padding: "15px 30px",
                  }}
                >
 <TableCell
  align="left"
  sx={{
    fontSize: "18px",
    color: "#09337B",
    cursor: "pointer",
    minWidth: "120px",
  }}
>
  <Stack direction="column">
    <Stack direction="row" alignItems="center">
      <Stack direction="row" alignItems="center">
      {CustomerData.completedAssess > 0 ? (
        <IconButton
          onClick={() =>
            handleToggleCollapse(CustomerData._id)
          }
        >
          {collapsedJobId === CustomerData._id ? (
            <MinusIcon />
          ) : (
            <AddIcon />
          )}
        </IconButton>
      ):null}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
          }}
        >
          {CustomerData.displayName}
        </div>
      </Stack>
    </Stack>
   
    <Collapse
      in={collapsedJobId === CustomerData._id}
      timeout="auto"
      unmountOnExit
    >
      <div style={{ padding: "10px" }}>
      <table style={{ width: "700%", borderCollapse: "collapse", border: "1px solid black" }}>
  <thead>
    
    <Stack direction="row" style={{ display: "grid", gridTemplateColumns: "2.3fr 1.2fr 1.9fr 2fr 1.6fr" }}>
      <th style={{ fontSize: "16px", }}>Name</th>
      <th style={{ fontSize: "16px", }}>Score</th>
      <th style={{ fontSize: "16px", whiteSpace: "nowrap" }}>Time Taken</th>
      <th style={{ fontSize: "16px", whiteSpace: "nowrap" }}>Accessed At</th>
      <th style={{ fontSize: "16px", whiteSpace: "nowrap" }}>Created At</th>
      <th style={{ fontSize: "16px", whiteSpace: "nowrap", gridColumn: "6", marginRight: "90px" }}>Download Report</th>
    </Stack>
  </thead>
  <tr>
      <th colSpan="6" style={{ borderBottom: "1px solid black" }}></th>
    </tr>
  <tbody>
    {assessments?.map((assessment) => {
      return (
        <tr key={assessment._id} style={{ marginTop: '100px' }}>
          <td>
            <Stack direction="row" spacing={12.5} style={{ fontSize: "16px", padding: "8px", display: "grid", gridTemplateColumns: "0.9fr 0.1fr 1.4fr 1.3fr 1.3fr" }}>
              <div>{assessment.assessments.name}</div>
              <div>{assessment.score}</div>
              <div>
  {`${Math.floor(assessment.timeTaken * 60 / 60)} minutes and ${Math.round(assessment.timeTaken * 60 % 60)} seconds`}
</div>





              <div><Moment format="DD-MM-YYYY">{assessment.accessedAt}</Moment></div>
              <div><Moment format="DD-MM-YYYY">{assessment.createdAt}</Moment></div>
              <div
                style={{
                  display: "inline-block",
                  gridColumn: "6",
                  marginRight: "150px",
                }}
                onClick={(e) => {
                  console.log("Button clicked");
                  downloadReport(e, assessment.reportURL);
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    zIndex: 1
                  }}
                >
                  <Download />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 0,
                      pointerEvents: "none"
                    }}
                  />
                </Typography>
              </div>
            </Stack>
          </td>
        </tr>
      );
    })}
  </tbody>
</table>

      </div>
    </Collapse>

  </Stack>
</TableCell>




                  <TableCell sx={{ fontSize: "18px" }}>
                    {CustomerData.email}
                  </TableCell>
                  <TableCell sx={{ fontSize: "18px" }}>
                    <Moment format="YYYY-MM-DD">
                      {CustomerData.createdAt}
                    </Moment>
                  </TableCell>
                  <TableCell sx={{ fontSize: "18px" }}>
                    {CustomerData.eduCom}
                  </TableCell>
                  <TableCell sx={{ fontSize: "18px" }}>
                    {CustomerData.completedAssess}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Divider />
          </TableContainer>
        ))}
    </>
  );
}
