import {
  Grid,
  Paper,
  Typography,
  Stack,
  Button,
  Skeleton,
  TextField,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const users = {
    logo: "",
    name: "user1",
    email: "user1@gmail.com",
    created: "30-11-2022 ",
    count: "2",
  };
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchField, setSearchField] = useState("");
  const handleChange = (e) => {
    const lowerCase = e.target.value.toLowerCase();
    setSearchField(lowerCase);
  };

  return (
    <>
      <Grid
        sx={{
          width: "100%",
          height: "100vh",
          background: "#F7F9FD",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={2}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            display="flex"
            justifyContent="space-between"
            sx={{ flexDirection: { sm: "column", lg: "row", md: "row" } }}
          >
            <Typography variant="text3M">Search/Filter :</Typography>
            <Paper
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "350px",
                padding: "8px 35px",
                boxShadow: "0px 8px 16px #00000014",
                borderRadius: "8px",
                marginRight: "20px",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                placeholder="User Name"
                onChange={handleChange}
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                  color: "#3A3A3A",
                }}
              />
            </Paper>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              sx={{ width: "150px" }}
              id="outlined-select-currency"
              select
              size="small"
              label="Created Date"
              onChange={handleChange}
            >
              <MenuItem>12-10-2022</MenuItem>
            </TextField>
            <Button sx={{ fontSize: "18px" }} variant="contained">
              {t("Search")}
            </Button>
          </Stack>
        </Grid>

        <>
          <Paper
            sx={{
              width: "100%",
              height: "70px",
              borderRadius: "5px",

              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 30px",
              display: "grid",
              gridTemplateColumns: "1.5fr repeat(3, 1fr)",
              marginBottom: "10px",
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 20px #0000001A",
            }}
          >
            <Stack direction="row" display="flex" alignItems="center">
              <Typography mr={0.5} variant="text6B">
                Name
              </Typography>
            </Stack>
            <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
              email
            </Typography>
            <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
              Created
            </Typography>
            <Typography variant="text6B" sx={{ color: "#3a3a3a" }}>
              Count
            </Typography>
          </Paper>
          <Paper
            sx={{
              width: "100%",
              borderRadius: "5px",
              gap: "10px",
              marginBottom: "10px",

              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 20px #0000001A",
            }}
          >
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      display: "grid",
                      gridTemplateColumns: "1.5fr repeat(3, 1.1fr)",
                      padding: "15px 30px",
                    }}
                  >
                    <Stack direction="row" alignItems="center">
                      <img
                        src={"img"}
                        alt="bg"
                        style={{
                          width: "44px",
                          height: "44px",
                          background: "#E1E7F1 0% 0% no-repeat padding-box",
                          borderRadius: "6px",
                        }}
                      />

                      <TableCell
                        align="left"
                        onClick={() => navigate("/CustomerDetails")}
                        sx={{
                          fontSize: "18px",
                          color: "#09337B",
                          cursor: "pointer",
                          minWidth: "120px",
                        }}
                      >
                        {users.name}
                      </TableCell>
                    </Stack>
                    <TableCell sx={{ fontSize: "18px" }}>
                      {users.email}
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      {users.created}
                    </TableCell>
                    <TableCell sx={{ fontSize: "18px" }}>
                      {users.count}
                    </TableCell>
                  </TableRow>
                </TableBody>

                <Divider />
              </Table>
            </TableContainer>
          </Paper>
        </>
      </Grid>
    </>
  );
};

export default Users;
