import axios from "axios";

const API = axios.create({
 baseURL:"https://api.zapilio.com/v2"

});

API.interceptors.request.use((req) => {
  if (sessionStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(sessionStorage.getItem("user")).token
    }`;
  }
  return req;
});

//Hirezap
export const Customers = (filter) =>
  API.get(
    `/stats?page=${filter.page}&size=${filter.size || 12}&startDate=${
      filter.startDate
    }&endDate=${filter.endDate}&name=${filter.name || ""}&segment=${
      filter.segment || ""
    }&sortBy=planEnd&sortDirection=${filter.sortDirection || "1"}`
  );
export const getCustomerDetails = (id) => API.get(`/stats/${id}/`);

export const updateSegment = (id, segment) =>
  API.patch(`/stats/${id}`, segment);

export const updateDate = (id, planEnd) => API.put(`/stats/${id}`, planEnd);

export const addNotes = (id, note) => API.post(`/stats/${id}`, note);
export const download = (customerid, jobid, customerName, jobName) => {
  API.get(`/stats/${customerid}/${jobid}`, {
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${customerName}_${jobName}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

export const applicantCsv = (
  customerId,
  jobId,
  custName,
  jobName,
  applicants
) => {
  return API.post("/stats/report/getApplicantCSV", {
    customerId,
    jobId,
    custName,
    jobName,
    applicants,
  }).then((response) => {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv;charset=utf-8" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${custName}_${jobName}_ApplicantList_${applicants}.csv`
    );
    document.body.appendChild(link);
    link.click();
  });
};

export const applicantResponseCsv = (jobId, custName, jobName, applicants) => {
  return API.post("/stats/report/getApplicantResponseCSV", {
    jobId,
  }).then((response) => {
    if (response.data) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${custName}_${jobName}__ApplicantResponses_${applicants}.csv`
      );
      document.body.appendChild(link);
      link.click();
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000); // Wait 2 seconds to ensure that the download is complete
      });
    }
  });
};

//skillzap
export const skillZapUsers = (filter) =>
  API.get(
    `/stats/skillzap/users/searchSkillZapUser?page=${filter.page}&size=${
      filter.size || 12
    }&search=${filter.search || ""}`
  );

export const getAssessments = (userId) =>
  API.post("/stats/skillzap/users/getAssessments", { userId: userId });
export const downloadskillZapUsers = (filter) => {
  return API.get(
    `/stats/skillzap/users/downloadSkillzapUser?page=${filter.page}&search=${
      filter.search || ""
    }`
  )
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `skillzap_users.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

//analytics
export const downloadUsers = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/tech/csv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${currentDate}_Technical_Completed.csv`); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

export const completedBehavioral = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/behav/csv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${currentDate}_Behavioral_Completed.csv`); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

export const signedupTechnical = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/tech/zeroapplicantcsv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${currentDate}_Technical_SignedUp.csv`); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

export const signedupBehavioral = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/behav/zeroapplicantcsv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${currentDate}_Behavioral_SignedUp.csv`); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

export const assessmentdroppedTechnical = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/tech/incompleteapplicantcsv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${currentDate}_Technical_AssessmentDropped.csv`
      ); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

export const assessmentdroppedBehavioral = () => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split("-")
    .reverse()
    .join("-");
  return API.get("/analytics/skillzap/behav/incompleteapplicantcsv")
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${currentDate}_Behavioral_AssessmentDropped.csv`
      ); // set filename to include current date
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};

//Dashboard
export const dashboard = () =>
  API.post("/stats/skillzap/users/getDashboardData");
export const topAssessments = () => API.get("/package/search");
export const downloadTopassessments = (filter, startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  let dateRangeString = "";
  if (filter.days === "custom") {
    const startDateString = `${startDateObj
      .getDate()
      .toString()
      .padStart(2, "0")}-${(startDateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${startDateObj.getFullYear()}`;
    const endDateString = `${endDateObj
      .getDate()
      .toString()
      .padStart(2, "0")}-${(endDateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${endDateObj.getFullYear()}`;
    dateRangeString = `${startDateString}_to_${endDateString}`;
  } else {
    dateRangeString = `last ${filter.days} days`;
  }
  const fileName = `${dateRangeString}.xlsx`;
  return API.post(
    `/stats/skillzap/users/downloadDashboardAssessments?days=${filter.days}`
  )
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.log(error);
      // handle the error here
    });
};
//Google signin
export const googleSignIn = (data) => API.post("auth/googleSignin", data);
